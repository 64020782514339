<template>
  <v-container class="d-flex justify-center">
    <v-card flat color="secondary" style="width: 600px;">
      <v-card-title>Login</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="my-3">
            <v-text-field solo :flat="isEmailFlat" @focusin="isEmailFlat=false" @focusout="isEmailFlat=true" hide-details
                          placeholder="email" type="text" v-model="email" autocomplete="on"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="my-3">
            <v-text-field solo :flat="isPasswordFlat" @focusin="isPasswordFlat=false" @focusout="isPasswordFlat=true" hide-details
                          placeholder="senha" type="password" v-model="password" autocomplete="on"/>
          </v-col>
        </v-row>
        <v-row dense v-if="showError"><v-col cols="12" class="font-italic error--text">Email ou senha incorretos!</v-col></v-row>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn color="accent" class="mb-3" style="width: 200px;" @click="emailAction"
               :disabled="!email || !password || password.length < 6 || !(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.trim()))">Entrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'Login',
  data() { return {
    email: null, password: null,
    isEmailFlat: true, isPasswordFlat: true, showError: false,
  } },
  mounted() { if (this.$store.getters.isLoggedIn) this.$store.getters.isFreela ? this.$router.push('/submeter') : this.$router.push('/submissoes'); },
  methods: {
    async emailAction() {
      this.showError = false;
      this.$store.commit('enableLoadingState');
      try {
        const response = await this.$store.dispatch('signInAction', {email: this.email.trim(), password: this.password});
        if (response[0] === 1) { this.email = null; this.password = null;
          this.$router.push(this.$store.getters.isFreela ? '/submissoes' : '/manual')
            .catch(()=>{}).finally(()=>this.$store.commit('disableLoadingState'));
        } else { this.showError = true; }
      } catch (e) { alert(`Ocorreu um erro: ${e.message}`); }
      finally { this.$store.commit('disableLoadingState'); this.password = null; }
    }
  }
}
</script>

<style scoped>

</style>
