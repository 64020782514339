import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Login', component: Login },
  { path: '/admin', name: 'Admin', component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue') },
  { path: '/teste', name: 'SkillTest', component: () => import(/* webpackChunkName: "skillTest" */ '../views/SkillTest.vue') },
  { path: '/submissoes', name: 'Submissions', component: () => import(/* webpackChunkName: "submissions" */ '../views/Submissions.vue') },
  { path: '/submeter', name: 'IDE', component: () => import(/* webpackChunkName: "IDE" */ '../views/IDE.vue') },
  { path: '/editor-live', name: 'LiveEditor', component: () => import(/* webpackChunkName: "LiveEditor" */ '../views/LiveEditor.vue') },
  { path: '/manual', name: 'Manual', component: () => import(/* webpackChunkName: "manual" */ '../views/Manual.vue') },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next)=>{
  store.dispatch('checkAuth', { forceRefresh: false }).then( () => {
    if (store.getters.isAdmin) { next(); return false; }
    else if (to.path === '/admin') { return false; }
    else if (!store.getters.isLoggedIn) { if (to.path === '/') { next(); } return false; }
    else if (to.path === '/') { store.getters.isFreela ? next('/submeter') : next('/submissoes'); return false; }
    else if (!store.getters.isFreela) { if (to.path !== '/submeter') { next(); } return false; }
    else if (to.path === '/teste') { if (from.path !== '/submeter') { next('/submeter'); } return false; }
    else { next(); return false; }
  }).catch(err=>{console.error(err)});
});

export default router
