import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import minifyTheme from 'minify-css-string'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'

Vue.use(Vuetify)

const vuetify = new Vuetify({
  theme: {
    options: {
      minifyTheme,
      customProperties: true,
    },
    dark: false,
    themes: {
      light: {
        primary: '#00CC66',
        secondary: '#DFE0E2',
        accent: '#7E5A9B',
        error: '#EB5160',
        info: '#1A8FE3',
        success: '#00CC66',
        warning: '#FFFC31'
      },
      dark: {
        primary: '#000000',
        secondary: '#333333',
        accent: '#7E5A9B',
        error: '#EB5160',
        info: '#1A8FE3',
        success: '#00CC66',
        warning: '#FFFC31'
      },
    }
  },
  icons: {
    iconfont: 'mdi'
  }
})

Vue.use(TiptapVuetifyPlugin, { vuetify, iconsGroup: 'mdi' });

export default vuetify
