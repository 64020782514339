import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations } from 'vuexfire'
import auth from './auth'
import examCreation from './examCreation'

Vue.use(Vuex)

export default new Vuex.Store({
  state: { loadingState: false, },
  mutations: {
    ...vuexfireMutations,
    enableLoadingState(state) { state.loadingState = true },
    disableLoadingState(state) { state.loadingState = false },
  },
  actions: {
  },
  modules: {
    auth, examCreation,
  }
})
