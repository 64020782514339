<template>
  <v-app>
    <Loading v-show="$store.state.loadingState"/>
    <v-app-bar app color="primary" elevation="0" class="white--text">
      <v-app-bar-title>Provas Militares</v-app-bar-title>
      <router-link v-if="$store.getters.isAdmin" to="/admin" class="links-footer">Admin</router-link>
      <v-spacer/>
      <v-btn v-if="!$store.getters.isLoggedIn" depressed color="primary" class="lighten-1" @click="$router.push('/')">
        Fazer Login
      </v-btn>
      <v-btn v-else depressed color="primary" class="lighten-1" @click="$vuetify.theme.dark = !$vuetify.theme.dark">
        Tema<v-icon style="color: white; margin-left: 1rem;" size="20">{{ $vuetify.theme.dark ? 'mdi-weather-night' : 'mdi-white-balance-sunny' }}</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main :style="$vuetify.theme.dark ? 'background-color: #000000 !important;' : ''">
      <v-container class="fill-height">
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer v-if="$store.getters.isLoggedIn" app fixed padless color="primary" class="white--text">
      <v-row justify="center" class="ma-0">
        <router-link to='/submissoes' target="_blank" style="padding: 6px 10px; text-decoration: none;">
          {{ $store.state.auth.user.displayName }}
        </router-link>
        <a href="https://youtu.be/sg3ABfqDeSc" target="_blank" style="padding: 6px 10px;">Vídeo Exemplo</a><v-spacer/>
        <div style="white-space: nowrap; align-self: center;">
          <router-link to='/editor-live' class="links-footer" target="_blank">Editor Live</router-link>
          <router-link :to="$store.getters.isFreela ? '/submeter' : '/teste'" class="links-footer" target="_blank">
            {{ $store.getters.isFreela ? 'SUBMETER EXAME' : 'PROVA SELETIVA' }}
          </router-link>
          <router-link to='/manual' class="links-footer" target="_blank">Como Escrever LaTeX</router-link>
        </div>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
  import Loading from '@/components/Loading';
  export default {
    name: 'App',
    components: { Loading },
  };
</script>

<style lang="scss">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @media print { #app { display: none; } }
  .v-application a { color: inherit !important; }
  .links-footer { margin: 3px 5px; padding: 3px 5px; text-decoration: none; border-radius: 10px; background-color: var(--v-primary); z-index: 999; }
  .links-footer:hover { background-color: var(--v-primary-lighten1); }
  .tiptap-vuetify-editor__content th { background-color: rgb(200, 200, 200, 0.2) !important; }
  .v-app-bar-title__content{ width: 200px !important; }

  .MathJax:focus, .mjx-chtml:focus, .MathJax_SVG:focus { outline: none; } /* remove border (not recommended by WCAG) */
  .MathJax_Display, .MJXc-display, .MathJax_SVG_Display { overflow-x: auto; overflow-y: hidden; }

  .enunciado { white-space: pre-wrap !important; font-size: 15px !important; }
  .enunciado p { line-height: 1.6 !important; width: 100%; }
  .enunciado table { border-collapse: collapse; border: 1px solid darkgray;
    /* horizontal scroll */ display: block; max-width: fit-content; margin: 0 auto; overflow-x: auto; white-space: nowrap; }
  .enunciado th { background-color: rgba(120, 120, 120, 0.4); border: 1px solid darkgray; text-align: center; padding: 8px; }
  .enunciado td { background-color: rgba(200, 200, 200, 0.1); border: 1px solid darkgray; text-align: center; padding: 8px; }
  .enunciado td p { margin-bottom: 0 }
  .enunciado th p { margin-bottom: 0 }
  .alternative-group svg { border-radius: 100%; border: gray 1px solid; }
  .v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label { display: inline-block !important; }
</style>
