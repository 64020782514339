import { db } from '@/main'
import { firestoreAction } from 'vuexfire'

const initialExamState = () => { return { examGlobals: {}, scheduledUpdateTime: Date.now() } };
const state = initialExamState();

const getters = {
  getInstitutions(state) { return state.examGlobals.institutions },
  getSubjects(state) { return state.examGlobals.subjects },
  getComplements(state) { return state.examGlobals.complements },
  getPendingExams(state) { return state.examGlobals.pendingExams },
  getCompletedExams(state) { return state.examGlobals.completedExams },
  getTags(state) { return state.examGlobals.tags },
};

const mutations = {
  saveExamGlobalsToStorage(state) {
    localStorage.setItem('examGlobals', JSON.stringify(state.examGlobals));
    localStorage.setItem('scheduledUpdateTime', (Date.now() + 600000).toString());
  },
  saveExamGlobalsFromStorage(state) {
    state.examGlobals = JSON.parse(localStorage.getItem('examGlobals'));
    state.scheduledUpdateTime = parseInt(localStorage.getItem('scheduledUpdateTime'));
  },
};

const actions = {
  bindExamData: firestoreAction( ({ commit, bindFirestoreRef }) => {
    bindFirestoreRef('examGlobals', db.collection('GlobalData').doc('ExamGlobals'), {reset: false})
      .then(() => {commit('saveExamGlobalsToStorage')})
      .catch(err => {console.error(err)});
  }),
  unbindExamData: firestoreAction(({ unbindFirestoreRef }) => {
    unbindFirestoreRef('examData'); localStorage.removeItem('examGlobals'); localStorage.removeItem('scheduledUpdateTime');
  }),
  startStore( { state, commit, dispatch } ) {
    if (localStorage.getItem('examGlobals') && Date.now() < state.scheduledUpdateTime) {
      commit('saveExamGlobalsFromStorage')
    } else { dispatch('bindExamData') }
  },
  uploadNewExamFromIDE: firestoreAction(async (context, payload) => {
    // return the promise so we can await the write
    console.log(payload.newExamID, payload.newExamTitle, payload.newExamData);
    const batch = db.batch();
    batch.set(db.collection('Exams').doc(payload.newExamID), payload.newExamData);
    batch.set(db.collection('GlobalData').doc('ExamGlobals'),
      {'completedExams': {[payload.newExamID]: payload.newExamTitle}}, {merge: true});
    for (let question in payload.newQuestions) {
      if (Object.prototype.hasOwnProperty.call(payload.newQuestions, question)) {
        batch.set(db.collection('AdminQuestions').doc(question), payload.newQuestions[question], {merge:true});
      }
    }
    batch.set(db.collection('SubmissionReports').doc(payload.newExamID), {
      authorId: payload.uid, isPaid: false, submissionTime: Date.now(), authorName: payload.uname, examName: payload.newExamTitle
    }, { merge: true });
    try { await batch.commit(); state.examGlobals.completedExams[payload.newExamID] = payload.newExamTitle; }
    catch (err) { alert(`Ocorreu um erro: ${err.code}. Por favor, entre em contato conosco.`); }
  }), // ONLY ES6
  async uploadNewSkillTest(context, {uname, uid, questions}) {
    try {
      await db.collection('SkillTests').doc(uid).set({
        name: uname, uid: uid, date: Date.now(), questions: questions, approvalCode: 0, // 0 - pending, 1 - approved, 2 - refused
      });
    } catch (err) { alert(`Ocorreu um erro: ${err.code}. Por favor, entre em contato conosco.`); }
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
