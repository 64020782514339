import { auth as firebaseAuth } from '@/main';
import { onAuthStateChanged, signInWithEmailAndPassword, getIdTokenResult, signOut, sendPasswordResetEmail } from 'firebase/auth';

const initialState = () => { return { user: null, admin: false, freela: false }; };
const state = initialState();

const getters = {
  getUser(state) { return state.user },
  isFreela(state) { return state.freela },
  isAdmin(state) { return state.admin },
  isLoggedIn(state) {
    return (!!state.user && !!Object.keys(state.user).length); // && state.user.emailVerified
  },
};

const mutations = {
  setUser(state, payload) {
    if (payload) {
      state.user = payload;
    } else {
      state.user = {};
    }
  },
  setAdminState(state, payload) { state.admin = payload },
  setFreelaState(state, payload) { state.freela = payload },
};

const actions = {
  checkAuth({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      onAuthStateChanged(firebaseAuth, user => {
        if (!!user && !!Object.keys(user).length) {
          commit('setUser', user);

          getIdTokenResult(user, payload.forceRefresh).then((idTokenResult) => {
            if ((idTokenResult.claims || {}).admin) {
              commit('setAdminState', true);
            } else { commit('setAdminState', false); }
            if ((idTokenResult.claims || {}).freela) {
              commit('setFreelaState', true);
            } else { commit('setFreelaState', false); }
            dispatch('startStore', { root: true }).catch(err => console.error(err));
            resolve();
          }).catch((err) => { console.log(err); reject(); });

        } else { commit('setUser', null); resolve(); }
      });
    });
  },
  async signInAction({commit}, payload) {
    try {
      let response = await signInWithEmailAndPassword(firebaseAuth, payload.email, payload.password);
      commit('setUser', response.user);
    } catch (err) { console.error(err); return [0, err] }
    return [1, ''];
  },
  signOutAction({commit, dispatch}) {
    signOut(firebaseAuth)
      .then(() => { commit('setUser', null); dispatch('unbindExamData', {root: true}); this.$router.push('/').catch(()=>{}); })
      .catch(err => {console.error(err)});
  },
  async changePasswordAction(context, payload) {
    try { await sendPasswordResetEmail(firebaseAuth, payload.email) }
    catch (err) { console.error(err); return [0, err] }
    return [1, 'Verifique o e-mail que te enviamos'];
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
