<template>
  <div class="text-center" style="position: fixed; top: 0; left: 0; background-color: rgba(0, 0, 0, .5); width: 100vw; height: 100vh; z-index: 999; padding: 20vh 0;">
    <i style="font-weight: bold; color: white; text-shadow: 0 0 20px black; font-size: xx-large;">Carregando. Não feche o site.</i>
  </div>
</template>

<script>
export default {
  name: "Loading"
}
</script>

<style scoped>
</style>
