import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { getFunctions } from 'firebase/functions'
import firebase from 'firebase/compat/app'; import "firebase/compat/firestore";

Vue.config.productionTip = false

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAYIUUVEmoXmMi9tyOcQx-Tb7cNCW6nbwg',
  authDomain: 'upwork-tex-job.firebaseapp.com',
  databaseURL: 'https://upwork-tex-job.firebaseio.com',
  projectId: 'upwork-tex-job',
  storageBucket: 'upwork-tex-job.appspot.com',
  messagingSenderId: '427625825505',
  appId: '1:427625825505:web:dc66d0a3bda5bfcb8963a0'
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const storage = getStorage(firebaseApp);
const functions = getFunctions(firebaseApp);

// Vuexfire depends on firestore v8
firebase.initializeApp(firebaseConfig);
const fieldValue = firebase.firestore.FieldValue;
const db = firebase.firestore();

const isDev = false;

if (isDev && (location.hostname === 'localhost')) {
  // import('firebase/auth').then((authModule) => { authModule.connectAuthEmulator(auth, 'http://localhost:9099'); });
  db.settings({ host: 'localhost:8081', ssl: false });
  import('firebase/storage').then((storageModule) => { storageModule.connectStorageEmulator(storage, 'localhost', 9199); });
  import('firebase/functions').then((functionsModule) => { functionsModule.connectFunctionsEmulator(functions, 'localhost', 5001); });
}
export { db, auth, storage, fieldValue, functions }

new Vue({
  router, store, vuetify, render: h => h(App)
}).$mount('#app')
